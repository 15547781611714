import React, { useState } from 'react';
import styled from 'styled-components';
import CheckBox from '../CheckBox';
import { setTodayNotUsingWritingGuide } from '../../util/todayNotUsingWritingGuide';
import { downloadFile } from '../../util/downloadFile';
import learning_goal_and_usage_guide from '../../asset/pdfs/learning_objectives_and_usage_guidance.pdf';
// import title_and_first_sentence_guide from '../../asset/pdfs/title_and_first_sentence_guide.pdf';

const WritingGuideVideoModal = ({ onClose }) => {
  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    if (checked) {
      setTodayNotUsingWritingGuide();
    }
    onClose();
  };

  return (
    <ModalWrapper>
      <ModalTopBar>
        <Icon onClick={handleClose}>
          <svg width="16px" height="16px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 21.32L21 3.32001"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 3.32001L21 21.32"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Icon>
      </ModalTopBar>
      <ModalContent>
        <video autoPlay loop controls>
          <source src="/videos/writing_guide.mp4" type="video/mp4" />
        </video>
        <SectionTitle>사용 전에 꼭 보세요.</SectionTitle>

        <PdfDownloadButton onClick={() => downloadFile(learning_goal_and_usage_guide, '학습 목표와 사용 안내.pdf')}>
          학습 목표와 사용 안내.pdf
        </PdfDownloadButton>
        {/* <PdfDownloadButton onClick={() => downloadFile(title_and_first_sentence_guide, '제목과 첫 문장 가이드.pdf')}>
          제목과 첫 문장 가이드.pdf
        </PdfDownloadButton> */}
      </ModalContent>
      <ModalFooter>
        <CheckArea>
          <CheckBox checked={checked} onChange={setChecked} />
          <span>오늘 하루는 안 볼게요.</span>
        </CheckArea>
        <Icon onClick={handleClose}>
          <svg width="16px" height="16px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 21.32L21 3.32001"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 3.32001L21 21.32"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <CloseText>창 닫기</CloseText>
        </Icon>
      </ModalFooter>
    </ModalWrapper>
  );
};

const PdfDownloadButton = styled.button`
  width: fit-content;

  & + & {
    margin-top: 16px;
  }
`;
const ModalFooter = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CheckArea = styled.div`
  margin-right: 48px;

  & > span:last-of-type {
    font-size: 14px;
    font-weight: 600;
  }
`;
const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: rgba(50, 144, 255, 1);
  margin: 16px 0 48px;
  @media (max-width: 768px) {
    font-size: 15px;
    font-weight: 600;
  }
`;
const CloseText = styled.p`
  margin: 0 0 0 8px;
  font-size: 14px;
  font-weight: 600;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const ModalTopBar = styled.div`
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
`;
const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 490px;
  background-color: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(70, 71, 57, 0.12);
  border: 1.5px solid #4a5568;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 999;
  @media (max-width: 768px) {
    width: calc(100% - 42px);
    max-width: 480px;
    z-index: 8;
    border-top: 1px solid #eaebed;
    border-radius: 0px;
    box-shadow: none;
    background-color: #f8f9fb;
  }
`;

export default WritingGuideVideoModal;
